import React, {  useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Divider, Grid } from 'rsuite';

import {info} from 'react-icons-kit/fa/info';
import {envelopeO} from 'react-icons-kit/fa/envelopeO';
import {shareSquareO} from 'react-icons-kit/fa/shareSquareO';
import {check} from 'react-icons-kit/fa/check';
import {fileTextO} from 'react-icons-kit/fa/fileTextO';

import Table from '../../components/Table';
import Title from '../../components/Title';
import Toast from '../../components/Toast';

import {clientsUsersStore, procedures, salesConfirmTicket, sendVisaForm} from '../../libs/services';
import ModalProcedureInfo from './components/ModalProcedureInfo';
import ProcedureModal from './register/ProcedureModal';
import SystemContext from '../../context/SystemContext';
import moment from 'moment';
import ModalTicket from './modals/ModalTicket';
import { useNavigate } from 'react-router-dom';

const Procedures = ({loader})=>{
    const navigate = useNavigate();
    const list = useRef();
    const infoModal = useRef(null);
    const procedure = useRef(null);
    const ticketModal = useRef(null);
    const {getPermission} = useContext(SystemContext);

    const columns = [        
        {title:'Folio', show:true},
        {title:'Fecha', show:true},
        {title:'No. Solicitantes', show:true},
        {title:'Trámite', show:true},
        {title:'Cliente', show:true},
        {title:'Correo electrónico', show:true},
        {title:'Estatus', show:true}
    ];

    const [data, setData] = useState([]);


    const getData = async ()=>{
        await loader.current.handleShow('Cargando...');

        let response = await procedures();

        if(response){
            let rows = [];
            response.forEach((res)=>{
                let procedure = '';
                res.clients.forEach((c)=>{
                    procedure = c.type === 'visa' ? 'Visa' : 'Pasaporte';
                })
                res.procedure = procedure;
                let obj = {
                    id: res.id,
                    row:[
                        res.folio,
                        moment(res.date).format('DD/MM/YYYY'),
                        res.no_clients,
                        res.package,                        
                        res.full_name,
                        res.email,
                        res.status
                    ],
                    buttons:[
                        {name:'details', icon:info, title:'Detalles', show:true, action:(id)=>showInfo(id)},
                        {name:'send_ticket', 
                            icon:envelopeO, 
                            title:'Enviar ficha', 
                            show:res.status === 'Ficha pendiente',
                            action:(option)=>sendTicket(res, option),
                            options:[
                                {key:1, label:'Enviar ficha'},
                                {key:2, label:'Entregar ficha'}
                            ]
                        },
                        {name:'resend_ticket', 
                            icon:shareSquareO, 
                            title:'Reenviar ficha', 
                            show:res.status === 'Con ficha', 
                            action:(option)=>sendTicket(res, option),
                            options:[
                                {key:1, label:'Enviar ficha'},
                                {key:2, label:'Entregar ficha'}
                            ]
                        },
                        {name:'confirm_payment', icon:check, title:'Confirmar pago de ficha', show:res.status === 'Con ficha', action:(id)=>confirmationTicket(id)},
                        {name:'send_form', 
                            icon:fileTextO, 
                            title:'Enviar formulario', 
                            show:(res.status === 'Ficha pagada' && res.package.indexOf('Visa') !== -1), 
                            action:(option)=>onSendVisaForm(res, option),
                            options:[
                                {key:1, label:'Enviar formato'},
                                {key:2, label:'Llenar formato'},
                            ]
                        },
                        //{icon:clipboard, title:'Continuar trámite', show:getPermission('procedures_continue'), action:(id, type)=>onEdit(id, type)},
                    ]
                }

                let count = 0;
                obj.buttons.forEach((btn)=>{
                    if(!btn.show){
                        count++;
                    }
                });
                
                if(count === obj.buttons.length){
                    obj.buttons = [];
                }

                rows.push(obj);
            })

            await list.current.resetTable();

            await setData(rows);

            setTimeout(async ()=>{
                await list.current.setTable();				
            }, 0)
        }

        await loader.current.handleClose();
    }

    const showInfo = (id)=>{
        infoModal.current.handleShow(id);
    }

    const sendTicket = (res, option)=>{
        if(option === 1){
            ticketModal.current.handleShow(res.id, res.email, res.procedure, 'send');
        }else{
            ticketModal.current.handleShow(res.id, res.email, res.procedure, 'deliver');
        }
        
    }

    const confirmationTicket = async (id)=>{
        await loader.current.handleShow('');
        let response = await salesConfirmTicket(id);
        if(response){
            response = await clientsUsersStore({id:id});
            if(response){
                if(response.error){

                }else{
                    Toast.fire('Correcto', 'Confirmación de pago de ficha', 'success');
                    getData();
                }
                
            }            
        }

        await loader.current.handleClose();
    }

    const onSendVisaForm = async (res, option)=>{
        if(option === 1){
            await loader.current.handleShow('Enviando...')
            let id = res.id;
            let response = await sendVisaForm({id:id});
            if(response){
                Toast.fire('Correcto', 'Se enviaron las instrucciones de llenado de formulario de visa', 'success');
            }

            await loader.current.handleClose();    
        }
        
        if(option === 2){
            navigate('/procedures/visa/details/'+res.id);
        }
    }

    /*const onEdit = (id, type)=>{
        if(type === 'Pasaporte'){
            navigate('/procedures/passport/'+id);
        }else{
            //navigate('/procedures/visa/'+id);
            Swal.fire('Info', 'El apartado de tramites de visa se encuentra en mantenimiento', 'info');
        }
    }*/

    useEffect(()=>{
       getData(); 
       // eslint-disable-next-line
    },[]);

    return(
        <Grid fluid className='content'>
            <Grid fluid>
                <Col xs={24} className="mb-2">
                    <Title screen="Trámites" action="Listado" />
                </Col>
            </Grid>            
            <Divider style={{marginTop:0}} />
            <Grid fluid>
                {getPermission('procedures_create') && (
                    <Col xs={24} style={{position:'relative'}}>
                        <Button 
                            appearance='primary' 
                            size='sm'
                            style={{position:'absolute', right:0, marginTop:25, marginRight:25, zIndex:1}}
                            onClick={()=>navigate('/procedures/new')}
                        >
                            Nuevo Trámite
                        </Button>
                    </Col>
                )}
                
                <Col xs={24}>
                    <div className='p-4 shadow rounded form-content'>
                        <Table 
                            columns={columns}
                            dataList={data}
                            ref={list}
                        />
                    </div>
                </Col>
            </Grid>
           
            <ModalProcedureInfo loader={loader} ref={infoModal} />
            <ProcedureModal ref={procedure} />
            <ModalTicket loader={loader} getData={getData} ref={ticketModal} />
        </Grid>
    )
}

export default Procedures;