import { Col, Divider, Grid,  Row, Tooltip, Whisper } from "rsuite"
import Input from "../../../components/Input";
import InputMask from "../../../components/InputMask";
import SelectForm from "../../../components/Select";

const Form = ({
    procedure,
    selection,
    errors,
    handleChange,
    errorCurp,
    countries,
    states
})=>{

    return (
        <Grid fluid className="data-form mb-3">
            <Row>
                <Col xs={24} lg={12} xl={8}>
                    <label>Nombres</label>
                    <Input
                        id="names"
                        value={procedure.client.names || ""}
                        handleChange={(e)=>handleChange(e, 'client', 'alpha')}
                        required
                    />
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <label>Ap. Paterno</label>
                    <Input 
                        id="lastname1"
                        value={procedure.client.lastname1 || ""}
                        handleChange={(e)=>handleChange(e, 'client', 'alpha')}
                        required
                    />
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <label>Ap. Materno</label>
                    <Input 
                        id="lastname2"
                        value={procedure.client.lastname2 || ""}
                        handleChange={(e)=>handleChange(e, 'client', 'alpha')}
                    />
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <label>CURP</label> 
                    {errorCurp !== '' && <span style={{fontSize:11, color:'red', marginLeft:8, position:'absolute', top:'-10px', left:'-3px'}}>{errorCurp}</span>}
                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>Si no conoce su CURP de click para consultar</Tooltip>}>
                        <a href="https://www.gob.mx/curp/" style={{fontSize:11}} target="_blank" rel="noreferrer">Consultar</a>
                    </Whisper>
                    
                    <Input 
                        id="curp"
                        value={procedure.client.curp || ""}
                        handleChange={(e)=>handleChange(e, 'client')}
                        required
                    />
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <label>F. nacimiento</label> 
                    {/*errors.errorBirth !== '' && <span className="error-text">{errors.errorBirth}</span>*/}
                    <InputMask
                        type="date"
                        id="birthdate"
                        value={procedure.client.birthdate}
                        handleChange={(e)=>handleChange(e, 'client')}
                        required
                    />
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <span>País </span>
                    <SelectForm
                        id="country"
                        value={procedure.client.country}
                        options={countries}
                        handleChange={(e)=>handleChange(e, 'client')}
                        required
                    />
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <span>Estado </span>
                    <SelectForm 
                        id="state"
                        value={procedure.client.state || ""}
                        handleChange={(e)=>handleChange(e, 'client')}
                        options={states}
                        required
                    />
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <span>Ciudad </span>
                    <Input 
                        id="city"
                        value={procedure.client.city || ""}
                        handleChange={(e)=>handleChange(e, 'client')}
                        required
                    />
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <label>Teléfono</label>
                    {errors.errorPhone !== '' && <span className="error-text">{errors.errorPhone}</span>}
                    <InputMask 
                        type="phone"
                        id="phone"
                        value={procedure.client.phone || ""}
                        handleChange={(e)=>handleChange(e, 'client')}
                        required
                    />
                </Col>
            </Row>
            {((selection.type.renew && selection.procedure.passport) || selection.procedure.visa) && (
                <>
                    <fieldset>
                        <legend>Datos de pasaporte</legend>
                        <Col xs={24} className="p-0">
                            <Col xs={24} lg={12} xl={8}>
                                <label>Número</label>
                                <Input 
                                    id="passport_number"
                                    value={procedure.passport.passport_number}
                                    handleChange={(e)=>handleChange(e, 'passport')}
                                    required
                                />
                            </Col>
                            <Col xs={24} lg={12} xl={8}>
                                <label>Expedición</label>
                                {errors.errorExpeditionPassport !== '' && <span className="error-text">{errors.errorExpeditionPassport}</span>}
                                <InputMask 
                                    type="date"
                                    value={procedure.passport.passport_expedition_date}
                                    id="passport_expedition_date"
                                    handleChange={(e)=>handleChange(e, 'passport')}
                                    required
                                />
                            </Col>
                            <Col xs={24} lg={12} xl={8}>
                                <label>Caducidad</label>
                                {errors.errorExpirationPassport !== '' && <span className="error-text">{errors.errorExpirationPassport}</span>}
                                <InputMask 
                                    type="date"
                                    value={procedure.passport.passport_expiration_date}
                                    id="passport_expiration_date"
                                    handleChange={(e)=>handleChange(e, 'passport')}
                                    required
                                />
                            </Col>
                        </Col>
                    </fieldset>

                    {(selection.type.renew && selection.procedure.visa) && (
                        <fieldset>
                            <Divider />
                            <legend>Datos de visa</legend>
                            <Col xs={24} className="p-0">
                                <Col xs={24} lg={12} xl={8}>
                                    <label>Número</label>
                                    <Input 
                                        id="visa_number"
                                        value={procedure.visa.visa_number}
                                        handleChange={(e)=>handleChange(e, 'visa')}
                                        required
                                    />
                                </Col>
                                <Col xs={24} lg={12} xl={8}>
                                    <label>Expedición</label>
                                    {errors.errorExpeditionVisa !== '' && <span className="error-text">{errors.errorExpeditionVisa}</span>}
                                    <InputMask 
                                        type="date"
                                        value={procedure.visa.visa_expedition_date}
                                        id="visa_expedition_date"
                                        handleChange={(e)=>handleChange(e, 'visa')}
                                        required
                                    />
                                </Col>
                                <Col xs={24} lg={12} xl={8}>
                                    <label>Caducidad</label>
                                    {errors.errorExpirationVisa !== '' && <span className="error-text">{errors.errorExpirationVisa}</span>}
                                    <InputMask 
                                        type="date"
                                        value={procedure.visa.visa_expiration_date}
                                        id="visa_expiration_date"
                                        handleChange={(e)=>handleChange(e, 'visa')}
                                        required
                                    />
                                </Col>
                                <Col xs={24} lg={12} xl={8}>
                                    <label>Lugar Expedición</label>
                                    <Input 
                                        id="visa_expedition_place"
                                        value={procedure.visa.visa_expedition_place}
                                        handleChange={(e)=>handleChange(e, 'visa')}
                                        required
                                    />                                    
                                </Col>
                            </Col>
                        </fieldset>
                    )}
                </>
            )}

            {selection.procedure.passport && (
                <fieldset>
                    <Divider />
                    <legend>Vigencia pasaporte</legend>
                    <Col xs={24} lg={12} xl={8}>
                        <label>Vigencia</label>
                        <select
                            className="form-control form-control-sm"
                            id="time"
                            name="time"
                            value={procedure.expirations.time}
                            onChange={(e)=>handleChange(e, 'expirations')}
                            required
                        >
                            <option value="">Seleccione</option>
                            <option value="1">1 años</option>
                            <option value="3">3 años</option>
                            <option value="6">6 años</option>
                            <option value="10">10 años</option>
                        </select>
                    </Col>
                    {procedure.expirations.time !== '' && (
                        <>
                            <Col xs={24} lg={12} xl={8}>
                                <label>Completo</label>
                                <input 
                                    className="form-control form-control-sm"
                                    value={'$ '+procedure.expirations.complete}
                                    onChange={()=>{}}
                                    disabled
                                />
                                
                            </Col>
                            <Col xs={24} lg={12} xl={8}>
                                <label>Beneficio del 50% de descuento</label>
                                <Row>
                                    <Col xs={12}>
                                        <input 
                                            className="form-control form-control-sm"
                                            value={'$ '+procedure.expirations.discount}
                                            onChange={()=>{}}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}
                    <Col xs={24} className="mt-2">
                        <ul>
                            <li>
                                <p style={{fontSize:12}} className="m-0">
                                    El pasaporte con <b>Vigencia de 1 año</b> solo se expide a <b>menores de 3 años</b>
                                </p>
                            </li>
                            <li>
                                <p style={{fontSize:12}} className="m-0">El beneficio de descuento aplica unicamente para personas mayores de 60 años de edad, 
                                    personas con discapacidad y trabajadores agricolas (con Canada). La <b>Discapacidad debera comprobarse</b> </p>
                            </li>
                            <li>
                                <p style={{fontSize:12}}>
                                    El beneficio con <b>Vigencia de 10 años</b> no aplica para trabajadores agricolas
                                </p>
                            </li>
                        </ul>
                    </Col>
                </fieldset>
            )}
        </Grid>
    )
}

export default Form;