import { useRef } from 'react';
import AppRoutes from './AppRoutes';
import Loader from './components/Loader';
import SystemState from './context/SystemState';
import ModalResetPassword from './pages/Users/ModalResetPassword';

const App = ()=>{
	const loader = useRef();
	const reset = useRef();

	return(
		<SystemState>
			<AppRoutes loader={loader} reset={reset} />
			
			<Loader ref={loader} />
			<ModalResetPassword ref={reset} loader={loader} />
		</SystemState>
	)
}

export default App;
