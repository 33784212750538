import { Button, Col, Divider, Grid,  Message, Row } from "rsuite"
import Title from "../../../components/Title"
import { useEffect, useRef, useState } from "react";
import {remove} from 'react-icons-kit/fa/remove';
import { Tab, Tabs } from "react-bootstrap";
import ButtonSelections from "./ButtonSelections";
import Form from "./Form";
import {addErrorToSelectedField, calculateAge, findInStorage, isAlpha, isValidForm, showCtrlError, swalAction} from '../../../libs/functions';
import Toast from "../../../components/Toast";
import { getCountries, getStates, salesStore, sitesList, usersFlyers, usersSellers } from "../../../libs/services";
import Payments from "./Payment";
import moment from "moment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Icon from "react-icons-kit";

const NewProcedure = ({loader})=>{
    const navigate = useNavigate();
    const payment = useRef(null);

    const selectionProcedure = {
        procedure:{ passport:false, visa:false },
        person:{ new:false, old:false },
        visaType:{ tourist:false, work:false },
        type:{ first:false, renew:false },
        options:{},
        documents:''
    };
    const procedureData = {
        client:{
            names:'',
            lastname1:'',
            lastname2:'',
            curp:'',
            birthdate:'',
            country:{value:'MÉXICO', label:'MÉXICO'},
            state:'',
            city:'',
            phone:''
        },
        passport:{
            passport_number:'',
            passport_expedition_date:'',
            passport_expiration_date:'',
            expirations:{
                time:'',
                complete:'',
                discount:''
            }
        },
        visa:{
            visa_number:'',
            visa_expedition_date:'',
            visa_expiration_date:'',
            visa_expedition_place:''
        },
        expirations:{
            time:'',
            complete:'',
            discount:''
        }
    };
    // eslint-disable-next-line
    const [errorEmail, setErrorEmail] = useState('');
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [errorForm, setErrorForm] = useState([]);
    const [showPayment, setShowPayment] = useState(false);
    const [sale, setSale] = useState({
        sites_id:'',
        seller_id:'',
        contact_type:'',
        flyer_id:'',
        methodPayment:'',
        reference:'',
        receipt:'',
        client:{
            name:'',
            email:'',
            phone:''
        },
        total:'',
        advancePayment:'',
        payable:'',

    });
    const [sites, setSites] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [flyers, setFlyers] = useState([]);
    const [clients, setClients] = useState([{
        tab:{
            key:'client1',
            label:"Cliente 1"
        },
        form: procedureData,
        errorsForm:{
            errorBirth:'',
            errorExpeditionPassport:'',
            errorExpirationPassport:'',
            errorExpeditionVisa:'',
            errorExpirationVisa:'',
            errorPhone:'',
        },
        selection: selectionProcedure
    }]);
    const [activeTab, setActiveTab] = useState('client1')

    const onLoad = async ()=>{
        let request = await getCountries();
        if(request){
            let items = request.countries.map((res)=> {
                let obj = {
                    value:res.name.toUpperCase(),
                    label:res.name.toUpperCase()
                };

                return obj;
            })
            
            setCountries(items);

            loadStates();
        }

        let response = await sitesList();
        if(response){
            setSites(response);
        }

        response = await usersSellers();
        if(response){
            setSellers(response);
        }

        response = await usersFlyers();
        if(response){
            setFlyers(response);
        }
    }

    /** carga estados */
    const loadStates = async (value = 'MÉXICO')=>{
        let response = await getStates(value);
        if(response){
            if(response.error){

                //getItems();
            }else{
                let items = response.map((res)=> {
                    let obj = {
                        value:res.name.toUpperCase(),
                        label:res.name.toUpperCase()
                    };
    
                    return obj;
                })
                
                setStates(items);            
            }            
            
        }
    }

    const onActiveOption = (e, index)=>{
        let key = e.key;
        let name = e.name;
        let value = e.value;

        let item = clients[index].selection;
        
        Object.keys(item).forEach((itemKey)=>{
            if(itemKey === key){
                Object.keys(item[itemKey]).forEach((element)=>{                    
                    item = {
                        ...item,
                        [key]:{
                            ...item[key],
                            [element]: element === name ? value : false
                        }
                    };                
                })
            }
        })
    
        item = {
            ...item,
            documents:  item.procedure.visa && item.type.first ? 'Pasaporte vigente' ://visa primera vez
                        item.procedure.passport && item.type.first ? 'CURP, INE' :// pasaporte primera vez
                        item.procedure.visa && item.type.renew ? 
                            item.options.expired ? 'Pasaporte vigente, Visa anterior' :
                            item.options.lost ? 'Pasaporte vigente' :
                            item.options.expired_years ? 'Pasaporte vigente' :  '' : //visa renovacion
                        item.procedure.passport && item.type.renew ? 
                            item.options.expired ? 'CURP, INE, Pasaporte anterior' :
                            item.options.lost ? 'CURP, INE' :
                            item.options.damaged ? 'CURP, Pasaporte' :
                            item.options.change ? 'CURP, INE, Acta de nacimiento, visa (en caso de tenerla)' : ''
                        : '', // pasaporte renovacio,            
        }
        
        if(item.procedure.visa && !item.type.renew){
            item = {
                ...item,
                options:{expired:false, lost:false, expired_years:false}
            };
        }

        if(item.procedure.passport && !item.type.renew){
            item = {
                ...item,
                options:{ expired:false, lost:false, damaged:false, change:false}
            };
        }        
        
        let elements = clients.map((client, i)=>{
            if(i === index){
                client.selection = item;
            }

            return client;
        });

        setClients(elements);
    }

    const handleChangeProcedureForm = (e, key, type, index)=>{
        let name = e.target.name;
        let value = e.target.value;

        let data = clients[index].form;
        let errors = clients[index].errorsForm;
        let selection = clients[index].selection;

        console.log(name, value, key, type, index)

        if(type === 'alpha'){
            if(value !== ''){
                if(value !== ''){
                    if(!isAlpha(value)){
                        let newVal = value.substring(0, value.length - 1);
    
                        value = newVal;
                    }
                }
            }
        }

        if(key === 'expirations'){
            let expirations = {
                time: value,
                complete: value === '1' ? '815' : 
                          value === '3' ? '1585' : 
                          value === '6' ? '2155' : 
                          value === '10' ? '3780' :'',
                discount: value === '1' ? '405' : 
                          value === '3' ? '795' : 
                          value === '6' ? '1075' : 
                          value === '10' ? '1890' :''
            };            
            
            data = {
                ...data,
                expirations: expirations
            };
        }

        if(name === 'birthdate' || name === 'expedition_date' || name === 'expiration_date'){
            if(value !== ''){
                let date = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
                let index = value.indexOf('_');

                if(name === 'birthdate'){                    
                    let edad = calculateAge(value);
                    /*errors.errorBirth = (
                        date === 'Invalid date' || index !== -1 ? 'Fecha no valida' :
                            (selection.person.old && (edad <= 18 || edad >= 100)) ? 'Debe ser mayor de edad' :
                            (selection.person.new && (edad >= 0 || edad < 18)) ? 'Debe ser menor de edad' : ''
                    );*/
                }
                if(key === 'passport'){
                    if(name === 'expedition_date'){
                        errors.errorExpeditionPassport = (date === 'Invalid date' || index !== -1 ? 'Fecha no valida' : '');
                    }
                    if(name === 'expiration_date'){
                        errors.errorExpirationPassport = (date === 'Invalid date' || index !== -1 ? 'Fecha no valida' : '');
                    }
                }else if(key === 'visa'){
                    if(name === 'expedition_date'){
                        errors.errorExpeditionVisa = (date === 'Invalid date' || index !== -1 ? 'Fecha no valida' : '');
                    }
                    if(name === 'expiration_date'){
                        errors.errorExpirationVisa = (date === 'Invalid date' || index !== -1 ? 'Fecha no valida' : '');
                    }
                }
                
            }else{
                if(name === 'birthdate'){
                    errors.errorBirth = '';
                }
                if(key === 'passport'){
                    if(name === 'expedition_date'){
                        errors.expeditionPassport = '';
                    }
                    if(name === 'expiration_date'){
                        errors.expirationPassport = '';
                    }
                }else if(key === 'visa'){
                    if(name === 'expedition_date'){
                        errors.expeditionVisa = '';
                    }
                    if(name === 'expiration_date'){
                        errors.expirationVisa = '';
                    }
                }
            }
            
            
        }else if(name === 'phone'){
            let index = value.indexOf('_');
            errors.errorPhone = (index !== -1 ? 'Teléfono no valido' : '');
        }

        if(name === 'country' && value !== ""){
            loadStates(value.value);
            data = {
                ...data,
                [key]:{
                    ...data[key],
                    state:""
                }
            }
        }

        data = {
            ...data,
            [key]:{
                ...data[key],
                [name]: name === 'email' || name === 'country' || name === 'state' ? value : value.toUpperCase()
            }
        }


        let elements = clients.map((client, i)=>{
            if(i === index){
                client.form = data;
            }

            return client;
        });
        

        setClients(elements);
    }

    const onValidSelection = (selection)=>{
        let error = ''
        let valid = true;
        Object.keys(selection).forEach((key)=>{
            if(key !== 'documents'){
                let items = Object.keys(selection[key]).map((item)=>{                    
                    let element = {
                        key:item,
                        value: selection[key][item]
                    };

                    return element;
                });

                
                let index = items.findIndex(obj => obj.value === true);
                
                if(index === -1){
                    if(selection.procedure.passport){
                        if(selection.type.first){
                            if(key !== 'options' && key !== 'visaType'){
                                valid = false;
                            }
                        }else{
                            if(key !== 'visaType'){
                                valid = false;
                            }                            
                        }
                    }else{
                        if(selection.type.first){
                            if(key !== 'options'){
                                valid = false;
                            }
                        }else{
                            valid = false;
                        }
                    }

                }

            }
            
        })

        if(!valid){
            error = 'Debe seleccionar todas las optiones';
        }
        
        return error;
    }

    const onValidForm = ({form, selection, errorsForm})=>{
        let procedure = form;
        let error = [];

        addErrorToSelectedField('country', procedure.client.country.value ? procedure.client.country.value : procedure.client.country);
        addErrorToSelectedField('state', procedure.client.state.value ? procedure.client.state.value : procedure.client.state);

        if(!isValidForm('div.data-form')){
            error.push('Campos incompletos');
        }
        
        if(
            //errorsForm.errorBirth !== '' || 
            errorsForm.errorExpeditionPassport !== '' || errorsForm.errorExpirationPassport !== '' || 
            errorsForm.errorExpeditionVisa !== '' || errorsForm.errorExpirationVisa !== '' ||
            errorsForm.errorPhone !== ''
        ){
            error.push('Existen errores en los datos capturados, verifique la información');
        }
        
        if(procedure.expirations.time === '1'){
            let edad = calculateAge(procedure.client.birthdate);

            if(edad > 3){
                error.push('La vigencia de 1 año aplica unicamente para menores de 3 años, verifica la fecha de nacimiento');
            }
        }
        
        if(procedure.client.birthdate !== '' && procedure.client.birthdate.indexOf('_') === -1){
            let edad = calculateAge(procedure.client.birthdate);
            if(selection.person.old){
                if(edad < 18){
                    //error.push('Debe ser mayor de edad');
                }
            }

            if(selection.person.new){
                if(edad > 18){
                    //error.push('Debe ser menor de edad');
                }
            }
        }

        return error ;
    }

    const verifyForm = async ()=>{
        let errors = [];

        clients.forEach((client, i)=>{
            let errorSelection = onValidSelection(client.selection);
            
            let errorForm = onValidForm(client);

            let arrayErrors = [errorSelection].concat(errorForm);

            arrayErrors.forEach((arr)=>{
                let item = {
                    error: arr,
                    clients:[]
                };

                let index = errors.findIndex(obj => obj.error === arr);
                if(index === -1){
                    if(arr !== ''){
                        item.clients.push(client.tab.label);

                        errors.push(item);
                    }
                    
                }else{
                    item = errors[index];

                    item.clients.push(client.tab.label);

                    errors[index] = item;
                }
            }) 
        });
        
        setErrorForm(errors);

        if(errors.length === 0){
            setShowPayment(true);
        }
    
    }

    const onAddClient = ()=>{
        let element = {
            tab:{
                key:'client'+(clients.length + 1),
                label:"Cliente "+(clients.length + 1)
            },
            form: procedureData,
            selection: selectionProcedure,
            errorsForm:{
                errorBirth:'',
                errorExpeditionPassport:'',
                errorExpirationPassport:'',
                errorExpeditionVisa:'',
                errorExpirationVisa:'',
                errorPhone:'',
            },
        }
        setClients([...clients, element]);
        setActiveTab('client'+(clients.length + 1));
    }

    const onDeleteClient = (i)=>{
        swalAction({
            title:'Alerta',
            text:'Realmente desea eliminar el cliente?',
            icon:'warning',
            textConfirm:'Si, eliminar',
            textcancel: 'No, Cancelar',
            fn:(i)=>{
                let elements = [];

                clients.forEach((client, index)=>{
                    if(index !== i){
                        elements.push(client);
                    }
                })

                elements = elements.map((element, i)=>{
                    element = {
                        ...element,
                        tab:{
                            key: 'client'+(i + 1),
                            label: 'Cliente '+(i + 1)
                        }
                    };

                    return element
                })

                setActiveTab('client1')
                setClients(elements);
            },
            values:i
        });
    }

    /**
     * pagos
     */
    const handleChangePaymentForm = (e, key)=>{
        let name = e.target.name;
        let value = e.target.value;

        let data = sale;
        data = {
            ...data,
            [name]: value 
        };    
        
        if(key !== ''){
            data = {
                ...data,
                [key]:{
                    ...data[key],
                    [name]: name !== 'email' ? value.toUpperCase() : value
                }
            }
        }

        setSale(data);

        if(value !== ''){
            showCtrlError(name);
        }

    }

    const onPayment = async ()=>{        
        loader.current.handleShow('Guardando...');
        let validForm = await payment.current.validateForm();
        
        if(validForm === ''){
            console.log(clients)
            let obj = {
                sales:{
                    sites_id: sale.sites_id,
                    date: moment().format('YYYY-MM-DD'),
                    folio: 'S'+moment().format('YYYYMMDDHHmmss'),
                    seller_id: sale.seller_id,
                    flyer_id: sale.flyer_id,
                    contact_type: sale.contact_type,
                    client:{
                        full_name: sale.client.name,
                        email: sale.client.email,
                        phone: sale.client.phone
                    },
                    amount: sale.advancePayment,
                    total: sale.total,
                    payable: sale.payable,
                    method_payment: sale.methodPayment,
                    platform: 'Local',
                    uuid: sale.reference,
                    receipt: sale.receipt,
                    package: sale.salePack,
                    status: 'Pagado'
                },
                data: clients.map((client)=>{
                    let procedure = {
                        documents: client.selection.documents
                    };
                    
                    Object.keys(client.selection).forEach((key)=>{                
                        if(key !== 'documents'){
                            Object.keys(client.selection[key]).forEach((item) =>{
                                let element = client.selection[key][item];
                                if(element){
                                    let value = '';
                                    if(key === 'procedure'){
                                        value = item;
                                    }else if(key === 'person'){
                                        value = item === 'new' ? 'Menor de edad' : 'Mayor de edad';
                                    }else if(key === 'visaType'){
                                        value = item === 'vork' ? 'Trabajo' : 'Turista';
                                    }else if(key === 'type'){
                                        value = item === 'first' ? 'Primera vez' : 'Renovación';
                                    }else if( key === 'options'){
                                        value = item === 'expired' ? 'Vencida o por vencer' :
                                                item === 'lost' ? 'Extraviado' :
                                                item === 'expired_years' ? 'Vencido +4 años' :
                                                item === 'damaged' ? 'Deteriorado' :
                                                item === 'change' ? 'Cambio de datos' : ''
                                    }
        
                                    procedure = {
                                        ...procedure,
                                        [key]: value
                                    };
                                }
                                
                            });
                        }               
                    });
        
                    let dataClients = {
                        procedure: procedure,
                        client:{
                            names: client.form.client.names,
                            lastname1: client.form.client.lastname1,
                            lastname2: client.form.client.lastname2,
                            birthdate: moment(client.form.client.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                            birthplace:{
                                country: client.form.client.country,
                                city: client.form.client.city,
                                state: client.form.client.state
                            },
                            documents:[{
                                type:'CURP', number: client.form.client.curp
                            }],
                            phones:[{
                                type:'Celular', number: client.form.client.phone
                            }]
                        }
                    };
        
                    if((client.selection.procedure.passport && client.selection.type.renew) || client.selection.procedure.visa){
                       
                        dataClients = {
                            ...dataClients,
                            passport:{
                                no_passport: client.form.passport.passport_number,
                                expedition_date: moment(client.form.passport.passport_expedition_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                                expiration_date: moment(client.form.passport.passport_expiration_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                            }
                        };
        
                        if(client.selection.procedure.passport){                    
                            dataClients = {
                                ...dataClients,
                                passport:{
                                    ...dataClients.passport,
                                    expirations: client.form.expirations
                                }
                            }                    
                        }
        
                        if(client.selection.procedure.visa && client.selection.type.renew){                    
                            dataClients = {
                                ...dataClients,
                                visa:{
                                    no_visa: client.form.visa.visa_number,
                                    expedition_date: moment(client.form.visa.visa_expedition_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                                    expiration_date: moment(client.form.visa.visa_expiration_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                                    expedition_place: client.form.visa.visa_expedition_place
                                }
                            }
                        
                        }
                    }

                    return dataClients;
                })
            };

            let response = await salesStore(obj)
            if(response){
                if(response.error){
                    let error = response.error;
                    if(response.error.indexOf('documents_number_unique') !== -1){
                        error = 'El número de INE y/o CURP ya se encuentra registrado, verifique la información';
                    }   
    
                    if(response.error.indexOf('clients_email_unique') !== -1){
                        error = 'El correo electrónico ya se encuentra registrado, verifique la información';
                    }
                    
                    
                    Swal.fire('Error',error, 'error');
                    
                }else{
                    onCancel();
                    Swal.fire('Se realizo correctamente el registro', 'La cita puede tardar entre 2 y 3 semanas, '+
						'si realiza algún otro trámite fuera de nuestras oficinas no habra reembolso. '+
						'El cliente recibira un correo electrónico con los detalles del tramite', 'success');
                }   
            }
            

        }else{
            Toast.fire('Error', validForm, 'error');
        }

        loader.current.handleClose();
    }

    const onCancel = ()=>{
        let countryToken = findInStorage('token_countries');

        sessionStorage.removeItem(countryToken);

        navigate('/procedures');
    }

    useEffect(()=>{
        loader.current.handleClose() 
        onLoad();
        // eslint-disable-next-line
    }, []);

    return(
        <Grid fluid className='content'>
            <Grid fluid>
                <Col xs={24} className="mb-2">
                    <Title screen="Trámites" action="Nuevo" />
                </Col>
            </Grid>            
            <Divider style={{marginTop:0}} />
            <Grid fluid>
                <Col xs={24}>
                    <Row className='p-4 shadow rounded form-content'>
                        {showPayment ? 
                            <Payments 
                                sale={sale}
                                setSale={setSale}
                                clients={clients}
                                handleChange={handleChangePaymentForm}
                                sites={sites}
                                sellers={sellers}
                                flyers={flyers}
                                ref={payment}
                            />
                        :                        
                            <Col xs={24}>
                                <Col xs={24} className="mb-2">
                                    <Button appearance="ghost" size="sm" onClick={()=>onAddClient()}>Agregar solicitante</Button>
                                </Col>
                                
                                {errorForm.length > 0 && ( 
                                    <Col xs={24}>
                                        <Message type="error" className="mb-2">
                                            <ul className="m-0">
                                                {errorForm.map((error, i)=>
                                                    <li xs={24} key={i}>
                                                        <label>
                                                            {error.error}
                                                            <b>
                                                            <span> {' ('}
                                                                {error.clients.map((c, j)=>
                                                                    c + (error.clients.length - 1 === j ? '' : ',')
                                                                )}
                                                            {')'}
                                                            </span>
                                                            </b> 
                                                        </label>
                                                    </li>
                                                )}
                                            </ul>
                                        </Message>
                                    </Col>
                                )}
                                
                                <Col xs={24}>
                                    <Tabs
                                        defaultActiveKey={activeTab}
                                        activeKey={activeTab}
                                        id="tabs-register"
                                        className="mb-3"
                                        onSelect={(e)=>setActiveTab(e)}
                                    >
                                        {clients.map((client, i)=>
                                            <Tab 
                                                eventKey={client.tab.key} 
                                                title={
                                                    <div className="flex align-items-center">
                                                        <div style={{display:'inline-block', padding:2}}>
                                                            <span>{client.tab.label}</span>
                                                        </div>
                                                        {i > 0 && (
                                                            <div style={{marginTop:'-3px'}}>
                                                                <Icon icon={remove} onClick={(e)=>onDeleteClient(i)} title="Eliminar"/>
                                                            </div>
                                                        )}
                                                    </div>
                                                } 
                                                key={i}
                                            >
                                                <Row>
                                                    <fieldset>
                                                        <legend>Datos de cliente</legend>
                                                        <Col xs={24} md={12} lg={10}>
                                                            <h6>Seleccione todas las opciones</h6>
                                                            <ButtonSelections 
                                                                selectionProcedure={client.selection}
                                                                onActiveOption={(e)=>onActiveOption(e, i)}
                                                                setErrorForm={setErrorForm}
                                                                
                                                            />
                                                        </Col>
                                                        <Col xs={24} md={12} lg={14}>
                                                            <h6>Llene</h6>
                                                            <Form 
                                                                handleChange={(e, key, type)=>handleChangeProcedureForm(e, key, type, i)}
                                                                procedure={client.form}
                                                                errors={client.errorsForm}
                                                                selection={client.selection}
                                                                errorEmail={errorEmail}
                                                                countries={countries}
                                                                states={states}
                                                                
                                                            />
                                                        </Col>
                                                    </fieldset>
                                                </Row>
                                            </Tab>
                                        )}
                                    </Tabs>
                                </Col>
                                
                            </Col>
                        }

                        <Col xs={24}>
                            <Divider />
                            {showPayment ? (
                                <>
                                    <Button appearance={'primary'} className="me-2" onClick={()=>setShowPayment(false)}>
                                        Anterior
                                    </Button>
                                    <Button appearance={'primary'} color="red" className="me-2" onClick={()=>onPayment()}>
                                        Pagar
                                    </Button>
                                </>                                
                            ) : (
                                <Button appearance={'primary'}  className="me-2" onClick={()=>verifyForm()}>
                                    Siguiente
                                </Button>
                            )}

                            <Button appearance={'default'} className="me-2" onClick={()=>onCancel()}>
                                Cancelar
                            </Button>
                        </Col>
                    </Row>
                </Col>
                
            </Grid>
        </Grid>
    )
}

export default NewProcedure;